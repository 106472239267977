import React from "react"
// import { graphql } from 'gatsby'
import Img from "gatsby-image"
import styled from "styled-components"
import Headline from "../components/common/MotionHeadline"
import Layout from "../components/common/Layout"
import SEO from "../components/common/SEO"

const PostContainer = styled.div`
  display: grid;
  height: 100vh;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(4, 1fr);
  /* gap: 0px 26px; */
  width: 100vw;
  grid-template-areas:
    ". post-t post post . "
    ". post-d post post ."
    ". post-d post post ."
    ". . . . .";
  @media (max-width: ${(props) => props.theme.screen.md}) {
    grid-template-columns: repeat(4, 1fr);
    /* margin: 0 0 9em; */
    grid-template-areas:
      " post-t post-t post-t post-t "
      "post post post post"
      "post post post post"
      " post-d post-d post-d post-d ";
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    grid-template-columns: repeat(4, 1fr);
    /* margin: 0 0 9em; */
    grid-template-areas:
      " post-t post-t post-t post-t "
      "post post post post"
      "post post post post"
      " post-d post-d post-d post-d "
      ". . . .";
  }
`

const PostImgWrapper = styled.section`
  grid-area: post;
  width: 100%;
  object-fit: cover;
  object-position: center center;
  max-width: 700px;
  display: grid;
  padding-top: 1em;
  max-height: 100%;
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    max-height: 100%;
  }
  @media (max-width: ${(props) => props.theme.screen.xs}) {
    padding-top: 2em;
    max-height: 100%;
  }
`
const PostDesc = styled.section`
  grid-area: post-d;
  padding: 1rem;
  place-content: center;
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    padding: 1rem;
  }
`

function Post({ pageContext }) {
  // console.log(pageContext)
  const hasDesc = pageContext.desc
  return (
    <Layout>
      <SEO
        title={pageContext.title}
        description={pageContext.desc || "nothin"}
        image={pageContext.photo}
        pathname={pageContext.slug}
        article
      />
      <PostContainer>
        <Headline headline={pageContext.title} />
        <PostImgWrapper>
          <Img fluid={pageContext.photo.fluid} objectFit="contain" />
        </PostImgWrapper>
        {hasDesc ? (
          <PostDesc>
            {" "}
            <p> {pageContext.desc.desc}</p>{" "}
          </PostDesc>
        ) : (
          <PostDesc>
            <p></p>
          </PostDesc>
        )}
      </PostContainer>
    </Layout>
  )
}
export default Post
