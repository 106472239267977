import React from "react"
import { motion } from "framer-motion"
import styled from "styled-components"

const BluryyHeadline = styled(motion.h1)`
  grid-area: post-t;
  padding: 1rem;
  justify-content: center;
`

function Headline({ headline }) {
    return (
      <BluryyHeadline
        initial={{ opacity: 0, filter: "blur(12px)" }}
        animate={{ opacity: 1, filter: "blur(0px)" }}
        transition={{
          duration: 0.7,
          ease: "easeOut"
        }}
        className="headline"
      >
        {headline}
      </BluryyHeadline>
    );
  }
export default Headline
